<template>
  <div>
    <stadium
      v-if="showStadium"
      @ready="onReady"
      @progress="onProgress"
    />

    <transition name="fade-in">
      <div
        v-if="showOverlay"
        key="1"
        class="relative min-h-screen text-normal
               flex flex-col justify-center bg-blue-overlay z-50"
      >
        <img
          src="@/assets/images/stadium-overlay.png"
          alt=""
          class="absolute top-0 left-0 w-full h-full object-contain"
        />

        <div class="px-14 xl:max-w-1/2 z-10">
          <h1
            class="mb-6 lg:mb-10 text-4xl font-light text-white
                   lg:text-6xl lg:leading-tight type-text"
          >
            <div :class="{ 'type-text__line': showTextWithAnimation }">
              A world-class venue
            </div>

            <div :class="{ 'type-text__line': showTextWithAnimation }">
              deserves a
              <span class="font-bold">
                world-class
              </span>
            </div>

            <div
              class="font-bold"
              :class="{ 'type-text__line': showTextWithAnimation }"
            >
              technology platform
            </div>
          </h1>
        </div>

        <div class="progress-bar">
          <div
            class="progress-bar__indicate"
            :style="{ width: `${progress}%` }">
          </div>
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <div
        v-if="!showOverlay && showStadium"
        key="2"
      >
        <div class="min-h-screen pt-20 pb-4 lg:pb-8 flex flex-col justify-end">
          <div class="relative z-10 px-8">
            <div class="text-center">
              <button
                class="border-2 border-blue rounded-full py-2 lg:py-6 px-5 lg:px-11
                    bg-blue text-sm font-bold text-white 2xl:text-xl
                      transition-colors
                    hover:bg-white
                      focus:outline-none focus:bg-white
                      hover:text-black
                      active:bg-white"
                @click="openSolutions"
              >
                Explore the concourse
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { actions } from '@/store';
import { Stadium } from '@/components';
import SplitType from 'split-type';

export default {
  name: 'StadiumPage',
  components: {
    Stadium,
  },
  data() {
    return {
      showStadium: false,
      showOverlay: true,
      actions,
      progress: 1,
      showTextWithAnimation: true,
    };
  },
  beforeDestroy() {
    this.showStadium = false;
  },
  mounted() {
    const { showOverlay } = this.$route.params;
    this.showTextWithAnimation = showOverlay;
    setTimeout(() => { this.showStadium = true; }, showOverlay ? 2500 : 500);
    this[actions.navigation.showLightIcon](true);
    if (!showOverlay) return;
    // eslint-disable-next-line no-new
    new SplitType(document.querySelector('.type-text'), {
      split: 'words, lines',
    });
  },
  methods: {
    openSolutions() {
      this.$router.push({
        name: 'concourse-view.index',
      });
    },
    onReady(isReady) {
      this.$nextTick(() => {
        this.showOverlay = !isReady;
        setTimeout(() => { this[actions.navigation.showLightIcon](false); }, 1200);
      });
    },
    onProgress(progress) {
      this.progress = progress;
    },
    ...mapActions([actions.navigation.showLightIcon]),
  },
};
</script>
